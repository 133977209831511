export default {
  methods: {
    time() {
      const date = new Date()

      // hour
      let hour = ''
      if (date.getHours() < 10) {
        hour = `0${date.getHours()}`
      } else {
        hour = date.getHours()
      }

      // minute
      let minute = ''
      if (date.getMinutes() < 10) {
        minute = `0${date.getMinutes()}`
      } else {
        minute = date.getMinutes()
      }

      // seconds
      let seconds = ''
      if (date.getSeconds() < 10) {
        seconds = `0${date.getSeconds()}`
      } else {
        seconds = date.getSeconds()
      }

      //
      // day
      let day = ''
      if (date.getDate() < 10) {
        day = `0${date.getDate()}`
      } else {
        day = date.getDate()
      }

      // month
      let month = ''
      if (date.getMonth() + 1 < 10) {
        month = `0${date.getMonth() + 1}`
      } else {
        month = date.getMonth() + 1
      }

      // seconds
      let year = ''
      if (date.getFullYear() < 10) {
        year = `0${date.getFullYear()}`
      } else {
        year = date.getFullYear()
      }

      return `${day}:${month}:${year}T${hour}:${minute}:${seconds}`
    },
  },
}
