<template>
  <div>
    <v-dialog v-model="dialog.category" width="500px">
      <v-card>
        <v-card-title> {{ $t('categoryMs') }}</v-card-title>
        <v-divider class="" />
        <v-card-text class="height-dialog">
          <v-row>
            <v-col cols="12">
              <v-data-table
                ref="tabel_category"
                v-model="selectTableCategory"
                disabled="true"
                :headers="headersCategory"
                :items="listCategory"
                :items-per-page="5"
                :loading="loading.category"
                loading-text="Loading Data"
                item-key="id"
                :options="paginationTableCategory"
                @update:items-per-page="updatePerPage($event)"
              >
                <!-- name -->
                <template v-slot:item.name="{ item }">
                  <v-text-field v-model="item.name" outlined dense hide-details></v-text-field>
                </template>
                <template v-slot:item.action="{ index }">
                  <v-icon small @click=";(dialog.categoryDelete = !dialog.categoryDelete), (indexCategory = index)">
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="" />
        <v-card-actions :class="$vuetify.breakpoint.xs ? 'justify-start ms-n3' : 'justify-end'">
          <v-spacer></v-spacer>
          <v-btn color="error" class="" @click="dialog.category = false"> Cancel </v-btn>
          <v-btn color="primary" @click="addCategory()">
            {{ $t('add') }}
          </v-btn>
          <v-btn color="success" :disabled="loadingSaveButton" @click="updateCategory()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="errorResponseValidationSnackbar" color="error" :top="true" style="z-index: 100000 !important">
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="errorResponseValidationSnackbar = false">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <!--     category deleted-->
    <v-dialog v-model="dialog.categoryDelete" width="300px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('delete') }}
          </h3>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <span> {{ $t('confirmDelete') }}</span>
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-end justify-end">
          <v-btn color="#ffeeee" @click="dialog.categoryDelete = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>

          <v-btn color="success" @click="deleteCategory">
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import PouchDb from '@/mixins/pouchDbMixin'
import Time from '@/mixins/time'
import { mdiAccountPlusOutline, mdiClose, mdiPencil, mdiPlus, mdiTrashCan } from '@mdi/js'

export default {
  components: {},
  mixins: [PouchDb, Time],
  props: [],
  data() {
    return {
      loadingSaveButton: false,

      // required: this.required,
      headers: [
        { text: '#', value: 'action' },
        { text: 'Template Name', value: 'templateName' },
        { text: 'Category', value: 'category' },
        { text: 'Quick Reply', value: 'quickReply' },
        { text: 'Last Update', value: 'lastUpdate' },
      ],
      headersCategory: [
        { text: 'Name', value: 'name' },
        { text: 'Action', value: 'action' },
      ],
      loading: {
        category: false,
      },
      listTemplate: [],
      listCategory: [],
      icons: {
        mdiAccountPlusOutline,
        mdiPlus,
        mdiTrashCan,
        mdiClose,
        mdiPencil,
      },
      dialog: {
        addMessageTEmplate: false,
        category: false,
        categoryDelete: false,
      },
      category: ['promotion', 'Notification'],
      selectTable: [],
      selectTableCategory: [],
      indexCategory: null,
      filter: '',
      uppy: null,
      categoryName: '',
      form: {
        name: '{{name}}',
        number: '{{phone_number}}',
      },
      formAddTemplate: {
        template_name: '',
        category: {},
        language: '',
        message: '',
        quick_reply: false,
        link_attachment: '',
        last_update: '',
        attachments: [],
        id: '',
      },
      status_message_template: 'add',
      propsUppyUploader: null,
      dataIndex: '0',
      itemPerPageSet: 5,
      paginationTableCategory: { page: 1, rowsPerPage: 5 },
      deleteCategoryList: [],
      errorResponseValidationSnackbar: false,
      errorMessage: '',
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  async mounted() {
    // this.connectSettings(false)
    await this.getDataCategory(true)
    this.setPageTableCategory()
  },
  methods: {
    //  category
    updatePerPage(data) {
      this.itemPerPageSet = data
    },
    setPageTableCategory() {
      this.$nextTick(() => {
        this.paginationTableCategory = { page: Math.ceil(this.listCategory.length / this.itemPerPageSet) }
      })
    },

    deleteCategory() {
      // Get the category to be deleted based on the current index
      const deleteCategory = this.listCategory[this.indexCategory]

      // Remove the category from the local list
      this.listCategory = this.listCategory.filter(category => category.id !== deleteCategory.id)

      // Check if category isLocal property is false, and store it to local state
      if (!deleteCategory.isLocal) {
        this.deleteCategoryList.push(deleteCategory.id)
      }

      // Close the delete category dialog
      this.dialog.categoryDelete = false
    },

    addCategory() {
      let dataArray = []
      dataArray = [...this.listCategory]
      dataArray.push({
        id: this.$Md5Hash(this.$nanoid()),
        name: '',
        isLocal: true,
      })
      this.listCategory = dataArray.map(dt => ({ id: dt.id, name: dt.name, isLocal: dt.isLocal }))
      this.setPageTableCategory()
    },

    async updateCategory() {
      // disable save button to avoid spam click
      this.loadingSaveButton = true

      // Filter the listCategory to only include elements where the name is not an empty string
      const filteredList = this.listCategory.filter(category => category.name !== '')

      // Keep track of existing category names
      const existingCategoryNames = new Set()
      const duplicatedCategoryNames = []

      // Loop through each element in the filtered list to check for duplicates
      for (const category of filteredList) {
        // If the category name is already in the set of existing names, add it to the duplicated names
        if (existingCategoryNames.has(category.name)) {
          duplicatedCategoryNames.push(category.name)
        } else {
          // Add the category name to the set of existing names
          existingCategoryNames.add(category.name)
        }
      }

      // If there are duplicated category names, log a warning and return early to prevent updating
      if (duplicatedCategoryNames.length > 0) {
        this.errorResponseValidationSnackbar = true
        this.errorMessage = `Category names "${duplicatedCategoryNames.join(', ')}" already exist.`
        console.warn(`Category names "${duplicatedCategoryNames.join(', ')}" already exist, skipping update.`)

        // Re-enabled save button
        this.loadingSaveButton = false
      } else {
        // Loop through each element in the filtered list to update or create the categories in pocketbase
        for (const category of filteredList) {
          try {
            // Attempt to update the category with the given id
            await clientPocketBase.collection('crm_message_template_categories').update(category.id, {
              name: category.name,
            })
          } catch (error) {
            // If the update failed with a 404 error, it means the category does not exist, so create it
            if (error.status === 404) {
              await clientPocketBase.collection('crm_message_template_categories').create({
                id: category.id,
                name: category.name,
                sub_id: this.user.sub_id,
              })
            }
          }
        }

        // Close the category dialog
        this.dialog.category = false

        // Re-enabled save button
        this.loadingSaveButton = false

        // emit event to update category
        this.$emit('update-category')
      }

      // Check if there are categories to be deleted
      if (this.deleteCategoryList.length) {
        // Loop through the list of categories to be deleted
        for (const category of this.deleteCategoryList) {
          try {
            // Delete the category from pocketbase
            await clientPocketBase.collection('crm_message_template_categories').delete(category)
          } catch (error) {
            // Log the error if deletion fails
            console.log(error, 'error deleting category')
          }

          // Close the category dialog
          this.dialog.category = false

          // Re-enabled save button
          this.loadingSaveButton = false

          // emit event to update category
          this.$emit('update-category')
        }
      }

      // Reset the delete list to initial state
      this.deleteCategoryList = []
    },

    // get category
    async getDataCategory(remote = false) {
      this.loading.category = true
      const categories = await clientPocketBase.collection('crm_message_template_categories').getFullList(400)
      this.listCategory = categories.map(category => ({ id: category.id, name: category.name }))
      this.loading.category = false
    },
  },
}
</script>
<style scoped>
.height-dialog {
  max-height: 400px;
  overflow-y: auto;
}
</style>
