<template>
  <v-card>
    <v-card-title class="align-start">
      <v-row>
        <v-col>
          <div class="d-flex">
            <span class="font-weight-semibold text-h6 text--white">Message Template</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ms-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiInformation }}
                </v-icon>
              </template>
              <div>
                <span>
                  {{ $t('messageTemplates.emptyTemplate1') }}
                </span>
              </div>
              <div>
                <span>
                  {{ $t('messageTemplates.emptyTemplate2') }}
                </span>
              </div>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="filter"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          v-if="selectTable.length > 0 && serverConnectionStatus"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            block
            @click="openBulkDeleteMessageTemplate()"
          >
            <v-icon
              dark
              center
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          v-else
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            block
            disabled
          >
            <v-icon
              dark
              center
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          offset-md="3"
          md="3"
        >
          <v-btn
            :disabled="!serverConnectionStatus"
            color="primary"
            block
            @click="showCategory()"
          >
            <v-icon
              dark
              left
            >
              {{ icons.mdiPlus }}
            </v-icon>
            {{ $t('add') }} {{ $t('messageTemplates.category') }}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-btn
            :disabled="!serverConnectionStatus"
            color="primary"
            block
            @click="showHideMessageTemplate()"
          >
            <v-icon
              dark
              left
            >
              {{ icons.mdiPlus }}
            </v-icon>
            {{ $t('add') }} {{ $t('messageTemplatesRead') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      v-model="selectTable"
      :headers="headers"
      :items="listTemplate"
      :items-per-page="5"
      :search="filter"
      item-key="id"
      show-select
      :loading="looading.table"
      loading-text="Loading... Please wait"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel'),
      }"
    >
      <!-- name -->
      <template v-slot:item.action="{ item, index }">
        <v-tooltip
          v-if="serverConnectionStatus"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              v-on="on"
              @click="editHideMessageTemplate(item)"
            >
              <v-icon>{{ icons.mdiPencil }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('messageTemplates.edit') }}</span>
        </v-tooltip>

        <v-tooltip
          v-if="serverConnectionStatus"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              v-on="on"
              @click="openDeleteMessageTemplate(index)"
            >
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('messageTemplates.delete') }}</span>
        </v-tooltip>

        <v-tooltip
          v-else
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              v-on="on"
              @click="editHideMessageTemplate(item, true)"
            >
              <v-icon>{{ icons.mdiEyeOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('messageTemplates.open') }}</span>
        </v-tooltip>

        <!-- <v-row v-if="serverConnectionStatus">
          <v-col cols="2">
            <v-icon @click="editHideMessageTemplate(item)">
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </v-col>
          <v-col
            cols="2"
            class="ml-4"
          >
            <v-icon @click="openDeleteMessageTemplate(index)">
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="2">
            <v-icon @click="editHideMessageTemplate(item, true)">
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </v-col>
        </v-row> -->
      </template>
      <template v-slot:item.template_name="{ item }">
        {{ item.template_name }}
      </template>
      <template v-slot:item.category="{ item }">
        {{ item.category.name }}
      </template>
      <template slot="no-data">
        <div class="mx-auto mb-10">
          <v-img
            class="mx-auto"
            width="256"
            length="256"
            src="@/assets/images/no template.png"
          ></v-img>
          <span class="text-h6 text--white font-weight-bold">{{ $t('messageTemplates.emptyList') }}</span>
        </div>
        <!-- <v-row align="center" class="py-2 padding-hero">
          <v-col class="text-center" lg="6" md="6" sm="12" cols="12">
            <div class="text-start hero-landing">
              <h1 class="hero">{{ $t('create') }} {{ $t('broadcasts.messageTemplate') }}</h1>
              <div class="hero-subtitle mb-2 hide-lottie">
                <div>
                  <span class="text--primary font-weight-semibold">{{ $t('messageTemplates.emptyTemplate1') }}</span>
                </div>
                <div>
                  <span class="text--primary font-weight-semibold"> {{ $t('messageTemplates.emptyTemplate2') }}</span>
                </div>
              </div>
              <p class="hero-subtitle hide-lottie">
                <span> {{ $t('broadcasts.templateHero.isByAdding') }}</span>
                <span class="text--primary font-weight-semibold"> {{ $t('broadcasts.templateHero.here') }}.</span>
              </p>
              <div class="my-3 mobile-tag">
                <div>
                  <span class="text--primary font-weight-semibold">{{ $t('messageTemplates.emptyTemplate1') }}</span>
                </div>
                <div>
                  <span class="text--primary font-weight-semibold">{{ $t('messageTemplates.emptyTemplate2') }}</span>
                </div>
                <div>
                  <span> {{ $t('broadcasts.templateHero.isByAdding') }}</span>
                </div>
                <div>
                  <span class="text--primary font-weight-semibold">  {{ $t('broadcasts.templateHero.here') }}.</span>
                </div>
              </div>
              <div class="justify-center try-btn">
                <v-btn elevation="2" class="button-goLogin" color="primary" @click="showHideMessageTemplate()">
                  <span style="color: white">{{ $t('create') }} {{ $t('broadcasts.messageTemplate') }}</span>

                  <v-icon color="white">
                    {{ icons.mdiChevronRight }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12" class="hide-lottie">
            <div class="mx-auto">
              <lottie-player
                src="https://assets9.lottiefiles.com/packages/lf20_q1g5qcgm.json"
                background="transparent"
                speed="1"
                class="mx-auto"
                loop
                autoplay
              ></lottie-player>
            </div>
          </v-col>
        </v-row> -->
      </template>
      <template v-slot:item.quickReply="{ item, i }">
        <div class="d-flex align-center flex-wrap">
          <v-checkbox
            v-model="item.quick_reply"
            class="m-0 p-0"
            hide-details
            :disabled="!serverConnectionStatus"
            @click="setQuickReply()"
          ></v-checkbox>
        </div>
      </template>
      <template #[`item.lastUpdate`]="{ item }">
        <div>
          {{ item.last_update.replace(/[T]/g, ' ') }}
        </div>
      </template>
    </v-data-table>
    <!--dialog-->
    <!--add message template-->
    <messageTemplate
      ref="message-template"
      @close-dialog="getMessageTemplate"
    />
    <!--category-->
    <category ref="dialog_category" />
    <!-- extra field-->
    <v-dialog
      v-model="dialog.categoryDelete"
      width="600px"
    >
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('delete') }}
          </h3>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <span v-if="status_message_template === 'deleted_message'">
            {{ $t('deleteTemplate') }}
          </span>
          <span v-if="status_message_template === 'selectMessage'">
            {{ $t('bulkDeleteTemplate') }}
          </span>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-end">
          <v-btn
            color="#ffeeee"
            @click="dialog.categoryDelete = false"
          >
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>

          <v-btn
            v-if="status_message_template === 'deleted_message'"
            color="error"
            @click="deleteMessageTemplate(dataIndex)"
          >
            {{ $t('delete') }}
          </v-btn><v-btn
            v-else-if="status_message_template === 'selectMessage'"
            color="error"
            @click="selectListTable()"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import category from '@/components/dialog/message_template/category.vue'
import messageTemplate from '@/components/dialog/message_template/message_template.vue'
import PouchDb from '@/mixins/pouchDbMixin'
import Time from '@/mixins/time'
import {
  mdiAccountPlusOutline,
  mdiClose,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiInformation,
  mdiPencil,
  mdiPencilOutline,
  mdiPlus,
  mdiTrashCan,
} from '@mdi/js'
import _filter from 'lodash/filter'
import _findIndex from 'lodash/findIndex'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'Template',
  components: {
    category,
    messageTemplate,
  },
  mixins: [PouchDb, Time],
  data() {
    return {
      headersCategory: [
        { text: 'Name', value: 'name' },
        { text: 'Action', value: 'action' },
      ],
      looading: {
        category: false,
        table: false,
      },
      listTemplate: [],
      listCategory: [],
      icons: {
        mdiAccountPlusOutline,
        mdiPlus,
        mdiTrashCan,
        mdiClose,
        mdiPencil,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiInformation,
      },
      dialog: {
        addMessageTEmplate: false,
        category: false,
        categoryDelete: false,
      },
      category: ['promotion', 'Notification'],
      selectTable: [],
      selectTableCategory: [],
      indexCategory: null,
      filter: '',
      uppy: null,
      categoryName: '',
      form: {
        name: '{{name}}',
        number: '{{phone_number}}',
      },
      formAddTemplate: {
        template_name: '',
        category: {},
        language: '',
        message: '',
        quick_reply: false,
        link_attachment: '',
        last_update: '',
        attachments: [],
        id: '',
        operator: '',
      },
      status_message_template: 'add',
      propsUppyUploader: null,
      dataIndex: 0,
      onReplyInput: false,
      newCategory: 1,
    }
  },
  computed: {
    headers() {
      return [
        { text: '#', value: 'action' },
        { text: this.$t('messageTemplates.templateName'), value: 'template_name' },
        { text: this.$t('messageTemplates.category'), value: 'category' },

        { text: 'Quick Reply', value: 'quickReply' },
        { text: this.$t('messageTemplates.lastUpdate'), value: 'lastUpdate' },
      ]
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },
  async mounted() {
    await this.checkStatusConnectionServer()
    this.looading.table = true

    // //console.log(this.user, 'aaaaaaaaa')
    // //console.log(this.$store.getters['auth/getListUsers'], 'ini list ops')

    // await this.connectSettings(true, false)
    // await this.$store.dispatch('link/fetchListTracker', {
    //   sub_id: this.user.sub_id,

    //   // token: this.user.token,
    // })
    await this.getMessageTemplate()
    const refs = this.$refs.dialog_category
    if (refs) refs.getDataCategory(true)

    // this.getDataTemplate('message_templates', conn).then(() => {
    //   this.looading.table = false

    //   if (conn) this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_SETTING_NAME, this.user)
    // })

    // this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, async payload => {
    //   if (payload.id === 'message_templates' || payload.id === 'category_message') {
    //     await this.getDataTemplate('message_templates', true)
    //     const refs = this.$refs.dialog_category
    //     if (refs) refs.getDataCategory(true)

    //     // //console.log(this.selectTable, 'selectTable after delete')
    //   }
    // })
  },
  methods: {
    async getMessageTemplate() {
      this.looading.table = true
      const messageTemplatePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'message_template',
      })

      if (messageTemplatePg.status) {
        const dataTemplate = messageTemplatePg.data === null ? [] : messageTemplatePg.data.value
        this.listTemplate = dataTemplate.filter(el => {
          if (this.user.role !== 'Admin') {
            if (el.operator && el.operator === this.user.email) return true

            if (!el.operator) return true

            return false
          }

          return true
        })
      }

      // //console.log(this.listTemplate)
      this.looading.table = false
    },

    //  category
    openDeleteMessageTemplate(index) {
      this.dataIndex = index
      this.dialog.categoryDelete = !this.dialog.categoryDelete
      this.status_message_template = 'deleted_message'
    },
    openBulkDeleteMessageTemplate() {
      this.dialog.categoryDelete = !this.dialog.categoryDelete
      this.status_message_template = 'selectMessage'
    },
    showCategory() {
      const refs = this.$refs.dialog_category
      refs.dialog.category = true
      refs.getDataCategory(true)
    },

    // list table
    async setQuickReply() {
      this.looading.table = true
      const newVal = JSON.parse(JSON.stringify(this.listTemplate))

      // //console.log('🚀 ~ file: index.vue ~ line 546 ~ setQuickReply ~ newVal', newVal)

      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'message_template',
        value: newVal,
      })

      this.looading.table = false

      // this.mixinUpdateMessageTemplate(newVal)
    },
    async getDataTemplate(status, remote = false) {
      this.looading.table = true
      const dataTemplate = await this.loadSettingsById('message_templates', remote)
      // eslint-disable-next-line no-param-reassign
      if (dataTemplate === null) status = 'null'
      switch (status) {
        case 'message_templates': {
          this.listTemplate = [...dataTemplate.value]
          this.looading.table = false
          break
        }
        case 'search_table': {
          const filter =
            this.filter === '' ? dataTemplate.value : _filter(dataTemplate.value, { template_name: this.filter })

          this.listTemplate = filter
          this.looading.table = false
          break
        }
        default: {
          this.looading.table = false
          this.listTemplate = []
        }
      }
    },
    async selectListTable() {
      const newVal = [...this.listTemplate]
      this.selectTable.forEach(d => {
        const indexCheck = _findIndex(newVal, f => f.id === d.id)
        newVal.splice(indexCheck, 1)
      })
      await this.mixinUpdateMessageTemplate(newVal, this.user)
      this.dialog.categoryDelete = false
      this.selectTable = []
      this.getMessageTemplate()
    },
    searchListTable() {
      this.getDataTemplate('search_table')
    },

    // add message template
    showHideMessageTemplate() {
      const refs = this.$refs['message-template']
      refs.dialog.addMessageTEmplate = true
      refs.getDataCategory()
      refs.status_message_template = 'add'
      refs.dataIndex = 0
      // eslint-disable-next-line prefer-destructuring
      refs.contactList = refs.contactListItems[0]
      refs.mustacheList = refs.mustacheAll[refs.contactList.value]
      refs.formAddTemplate = {
        template_name: '',
        category: {},
        language: '',
        message: '',
        quick_reply: false,
        link_attachment: '',
        last_update: '',
        attachments: [],
        contact_list: refs.contactListItems[0],
        id: '',
        operator: '',
      }
      refs.propsUppyUploader = null
      refs.categoryName = null
    },
    editHideMessageTemplate(data, show = false) {
      const refs = this.$refs['message-template']
      const index = uuidv4()
      refs.getMessageTemplate(data, index, show)
    },
    async deleteMessageTemplate(index) {
      const newVal = [...this.listTemplate]
      newVal.splice(index, 1)
      await this.mixinUpdateMessageTemplate(newVal, this.user)
      this.dialog.categoryDelete = false
      this.getMessageTemplate()
    },
  },
}
</script>
<style lang="scss" scoped>
.alert-danger {
  background-color: #ff4c51;
  width: 90px;
  height: 90px;
  border-radius: 100%;
}

.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
    width: 200px;
  } */
}
</style>
